import "./Reservations.scss";

import { useIonRouter } from "@ionic/react";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useCalendarListing } from "queries";
import { PublicReview } from "types/reservation.types";

import BookedByItem from "components/@common/BookedByItem";
import LinkUnstyled from "components/@common/LinkUnstyled";
import { getListingId } from "components/@listing-detail/utils";
import { formatDateRange } from "components/@reservations/ReservationDetail/utils";
import ReservationStatusChip from "components/@reservations/ReservationStatusChip/ReservationStatusChip";

import ReservationSkeletonBox from "./ReservationSkeleton";

type CombinedReservation = {
  id?: string;
  guestFullName?: string;
  guestsCount?: number;
  checkInDate: string;
  checkOutDate: string;
  plannedArrival?: string;
  plannedDeparture?: string;
  otaLogoUrl?: string;
  otaName?: string;
  hostPayout?: number;
  type?: null;
  ownerFullName: string | null;
  confirmationCode?: string;
  publicReview?: PublicReview;
  guestyId?: string;
  cleaningRequired?: boolean;
  numberOfGuests?: number;
  numberOfNights?: number;
  isOwnerReservation: boolean;
  isVerified: boolean | null;
  status?: string;
};

const Reservations = () => {
  const router = useIonRouter();
  const listingId = getListingId();
  const startDate = new Date();
  const endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 1);

  const { data, isLoading } = useCalendarListing(
    listingId || "",
    format(startDate, "yyyy-MM-dd"),
    format(endDate, "yyyy-MM-dd"),
    {
      enabled: !!listingId,
    },
  );

  const combinedReservations: Array<CombinedReservation> = [
    ...(data?.attributes.reservations || []).map((reservation) => ({
      ...reservation,
      isOwnerReservation: false,
    })),
    ...(data?.attributes.ownerReservations || []).map((reservation) => ({
      ...reservation,
      isOwnerReservation: true,
    })),
  ];

  combinedReservations.sort((a, b) => {
    const dateA = new Date(a.checkInDate);
    const dateB = new Date(b.checkInDate);
    return dateA.getTime() - dateB.getTime();
  });

  const filteredReservations = combinedReservations
    .filter((reservation) => reservation.status !== "canceled")
    .slice(0, 4);

  if (!filteredReservations.length && !isLoading) {
    return null;
  }

  return (
    <div className="reservations-block">
      <div className="reservations-title">
        <h5>
          <FormattedMessage id="nav.reservations" />
        </h5>

        <LinkUnstyled onClick={() => router.push(Routes.Reservations)}>
          <FormattedMessage id="listing_overview.reservations.view_all" />
        </LinkUnstyled>
      </div>

      <div className="reservations">
        {isLoading
          ? Array.from({ length: 4 }).map((_, index) => (
              <ReservationSkeletonBox key={index} />
            ))
          : filteredReservations.map((reservation) => (
              <div
                key={`${reservation.id}-${reservation.guestyId}`}
                className="reservation"
              >
                <div className="reservation-item">
                  <div>
                    <ReservationStatusChip
                      status={reservation.status ?? ""}
                      checkInDate={reservation.checkInDate}
                      checkOutDate={reservation.checkOutDate}
                    />
                  </div>

                  <BookedByItem
                    name={
                      reservation.isOwnerReservation
                        ? reservation.ownerFullName || ""
                        : reservation.guestFullName || ""
                    }
                    verified={!!reservation.isVerified}
                    isOwnerReservation={reservation.isOwnerReservation}
                  />

                  <p className="margin-0" style={{ fontWeight: 500 }}>
                    <FormattedMessage
                      id="listing_overview.reservations_item.info"
                      values={{
                        dateRange: formatDateRange(
                          reservation.checkInDate,
                          reservation.checkOutDate,
                        ),
                        guests: reservation.numberOfGuests,
                      }}
                    />
                  </p>
                </div>

                <div>
                  <LinkUnstyled
                    onClick={() =>
                      router.push(
                        generatePath(Routes.ReservationDetails, {
                          listingId,
                          reservationId: reservation.id || "",
                        }),
                      )
                    }
                  >
                    <FormattedMessage id="listing_overview.reservations_item.view" />
                  </LinkUnstyled>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Reservations;

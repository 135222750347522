import "./TopNav.scss";

import { IonHeader, IonIcon, IonLabel, IonRouterLink } from "@ionic/react";
import logo from "images/huswell-logo.svg";
import { FormattedMessage } from "react-intl";
import { matchPath, useLocation } from "react-router";

import { Routes } from "constants/routes.constants";

import VerifyEmailBanner from "components/@common/VerifyEmailBanner";
import { useTabs } from "components/@routes/AppRoutes/tabs";

import AccountMenu from "./AccountMenu";
import DesktopMenu from "./DesktopMenu";

type Props = {
  emailVerified: boolean;
};

const TopNav = ({ emailVerified }: Props) => {
  const tabs = useTabs("desktop");
  const location = useLocation();

  const isActive = (path: string) => {
    return matchPath(location.pathname, { path });
  };

  return (
    <IonHeader className="top-nav-header">
      <div className="top-nav">
        <div className="left">
          <IonRouterLink routerLink={Routes.Listings}>
            <IonIcon className="logo" icon={logo} />
          </IonRouterLink>
        </div>
        <div className="pages">
          {tabs.map(({ icon, label, route, disabled }) => (
            <IonRouterLink
              routerLink={disabled ? undefined : route}
              key={route}
              aria-disabled={disabled}
              className={`${disabled ? "router-link-disabled" : ""}`}
            >
              <div className={`router-link ${isActive(route) ? "active" : ""}`}>
                <IonIcon icon={icon} />
                <IonLabel>
                  <FormattedMessage id={label} />
                </IonLabel>
              </div>
            </IonRouterLink>
          ))}

          <DesktopMenu />
        </div>

        <div className="right">
          <AccountMenu />
        </div>
      </div>

      <VerifyEmailBanner type="desktop" emailVerified={emailVerified} />
    </IonHeader>
  );
};

export default TopNav;

import { useIonRouter } from "@ionic/react";
import { useEffect } from "react";

import { Routes } from "constants/routes.constants";

const enabledGtmRoutes = [
  Routes.SignUpContinue,
  Routes.CreateAccount,
  Routes.SignUpWelcome,
  Routes.SignUpDetails,
];

export const useGoogleTagManager = () => {
  const router = useIonRouter();
  const currentPath = router.routeInfo.pathname;

  useEffect(() => {
    const isEnabledRoute = enabledGtmRoutes.some(
      (route) => currentPath === route,
    );

    if (!isEnabledRoute) {
      // Remove GTM script and dataLayer if we're not on a signup route
      const existingScript = document.getElementById("google-tag-manager");
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
      delete window.dataLayer;
      return;
    }

    // Don't initialize GTM if it's already initialized
    if (window.dataLayer && document.getElementById("google-tag-manager")) {
      return;
    }

    // Create and add GTM script if it doesn't exist
    const script = document.createElement("script");
    script.id = "google-tag-manager";
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NDNFD2K');
    `;

    document.head.appendChild(script);
  }, [currentPath]);
};
